
import { FSXABaseSection } from 'fsxa-pattern-library'
import { Component } from 'nuxt-property-decorator'
import { File, Image, Option } from 'fsxa-api'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import { IGalleryContent } from '../../../shared/general/interfaces/IGalleryContent'
import { mapImage } from '../../../shared/general/services/ImageService'
import { buildPlayerElements } from '../../../shared/general/services/VideoUrlService'
import normalizeDataEntries from '../../../shared/fsxa/services/DataEntryService'
import { TDataEntry } from '../../../shared/fsxa/types/TDataEntry'

type TGalleryElement = {
  st_image_video ?: boolean
  st_image ?: Image
  st_image_alt_text ?: string
  st_image_decorative ?: boolean
  st_image_title_text ?: string
  st_video_format ?: Option
  st_webm ?: File
  st_ogv ?: File
  st_mp4 ?: File
  st_video_id ?: string
  st_vimeo_privacy_hash ?: string
  st_image_caption ?: string
  st_headline ?: string
  st_teaser_text ?: string
  st_zoom_image ?: Image
  st_zoom_image_alt_text ?: string
  st_teaser_text_lightbox ?: string
}

interface IPayload {
  st_lightbox ?: boolean
  st_teaser_elements ?: TDataEntry<TGalleryElement>[]
  st_headline ?: string
}

@Component({
  name: 'StGallery',
  components: {
    BaseGridLayout,
    Gallery: () => import('../../Gallery.vue'),
  },
})

export default class StGallery extends FSXABaseSection<IPayload> {
  get headline () : string {
    return this.payload.st_headline || ''
  }

  get lightbox () : boolean {
    return this.payload.st_lightbox || false
  }

  private get anchorName () : string | undefined {
    return this.$store.getters['AnchorLinks/getAnchorNameForSection'](this.id)
  }

  get elements () : IGalleryContent[] {
    return normalizeDataEntries(this.payload.st_teaser_elements).map((element : TGalleryElement) => ({
      title: element.st_headline,
      description: element.st_teaser_text || '',
      mediaType: element.st_image_video ? 'image' : 'video',
      image: mapImage(element.st_image, element.st_image_alt_text, element.st_image_title_text, element.st_image_decorative),
      caption: element.st_image_caption,
      video: {
        id: element.st_video_id || '',
        format: element.st_video_format?.key?.toLowerCase() === 'html5' ? 'internal' : 'external',
        htmlPlayerElements: buildPlayerElements({
          mp4: element.st_mp4,
          webm: element.st_webm,
          ogv: element.st_ogv,
        }),
        privacyHash: element.st_vimeo_privacy_hash,
      },
    })) || []
  }
}


import { Image } from 'fsxa-api'
import { FSXABaseLayout } from 'fsxa-pattern-library'
import { Component, Prop } from 'nuxt-property-decorator'
import TMetaProperty from '../../../shared/fsxa/types/TMetaProperty'
import ITagData from '../../../shared/fsxa/interfaces/ITagData'
import IBaseMetaData from '../../../shared/general/interfaces/IBaseMetaData'
import mapToResolutions from '../../../shared/general/services/ImageService'
import IAnchorLink from '../../../shared/fsxa/interfaces/IAnchorLink'
import { IResolution } from '../../../shared/fsxa/interfaces/IResolution'
import { formatJoinFromToDate } from '../../../shared/general/services/DateTimeService'
import { TRemoteDatasetIndex } from '../../../shared/fsxa/types/TRemoteDataset'
import getOrFetchRemoteDatasets, { getRemoteDatasetsFromStore } from '../../../shared/fsxa/services/RemoteDatasetService'
import IServiceData from '../../../shared/fsxa/interfaces/IServiceData'

interface IPayload {
  pt_headline ?: string
  pt_description ?: string
  pt_display_social_share_bar ?: boolean
  pt_robots_index_follow ?: boolean
  pt_og_title ?: string
  pt_og_site_name ?: string
  pt_og_description ?: string
  pt_og_img ?: Image
  pt_twitter_card ?: string
  pt_twitter_site ?: string
  pt_tags ?: ITagData[]
  pt_tags_remote ?: TRemoteDatasetIndex
  pt_service_remote ?: TRemoteDatasetIndex
  pt_filter_industry_remote ?: TRemoteDatasetIndex
  pt_filter_service_division_remote ?: TRemoteDatasetIndex
  pt_filter_service_object_remote ?: TRemoteDatasetIndex
  pt_additional_tags ?: string
  pt_publish_from ?: string
  pt_publish_to ?: string
  pt_anchor_links ?: IAnchorLink
  pt_location_type_remote ?: TRemoteDatasetIndex
  pt_use_location_reference ?: boolean
  pt_show_onlineappointment ?: boolean
}

@Component({ name: 'BasePageMetaTags' })
export default class BasePageMetaTags extends FSXABaseLayout<IPayload> {
  @Prop() searchHeadline ?: string

  private metaTagsArr : TMetaProperty[] = []

  public pushMetaName = (name : string, content ?: string) => !!content && this.metaTagsArr.push({ name, content })

  public pushTag = (tag : ITagData) => {
    if (!tag?.id || !tag?.data?.tt_name) return
    this.pushMetaName('tag', `${tag.id}||${tag.data.tt_name}`)
    this.pushMetaName('tag_id', `${tag.id}`)
  }

  private pushMetaProp = (property : string, content ?: string) => !!content && this.metaTagsArr
    .push({ property, content })

  private getMetaTags = () : TMetaProperty[] => this.metaTagsArr

  async mounted () {
    await this.fetchRemoteDatasetsMetaTags()
  }

  async serverPrefetch () {
    await this.fetchRemoteDatasetsMetaTags()
  }

  async fetchRemoteDatasetsMetaTags () : Promise<void> {
    await Promise.all([
      getOrFetchRemoteDatasets(this.tagsRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.serviceRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.filterIndustryRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.filterServiceDivisionRemoteDatasetIndex),
      getOrFetchRemoteDatasets(this.filterServiceObjectRemoteDatasetIndex),
    ])
  }

  private get tagsRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.tagsRemoteDatasetIndex)
  }

  public get tagsRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.metaData.tagsRemote
  }

  private get serviceRemote () : IServiceData | undefined {
    return getRemoteDatasetsFromStore(this.serviceRemoteDatasetIndex)[0]
  }

  private get serviceRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.metaData.serviceRemote
  }

  private get filterIndustryRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterIndustryRemoteDatasetIndex)
  }

  private get filterIndustryRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.metaData.filterIndustryRemote
  }

  private get filterServiceDivisionRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterServiceDivisionRemoteDatasetIndex)
  }

  private get filterServiceDivisionRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.metaData.filterServiceDivisionRemote
  }

  private get filterServiceObjectRemote () : ITagData[] {
    return getRemoteDatasetsFromStore(this.filterServiceObjectRemoteDatasetIndex)
  }

  private get filterServiceObjectRemoteDatasetIndex () : TRemoteDatasetIndex | undefined {
    return this.metaData.filterServiceObjectRemote
  }

  private get filters () : ITagData[] {
    return [...this.filterServiceObjectRemote, ...this.filterServiceDivisionRemote, ...this.filterIndustryRemote]
  }

  public get isContentProjectionPage () : boolean {
    return !!this.currentDataset
  }

  private get payloadContentProjection () : IBaseMetaData {
    const data = this.currentDataset?.data
    return {
      headline: data?.tt_headline,
      description: data?.tt_description,
      displaySocialShareBar: data?.tt_display_social_share_bar,
      robotsIndexFollow: data?.tt_robots_index_follow,
      ogTitle: data?.tt_og_title,
      ogSiteName: data?.tt_og_site_name,
      ogDescription: data?.tt_og_description,
      ogImg: data?.tt_og_img,
      twitterCard: data?.tt_twitter_card || data?.pt_twitter_card,
      twitterSite: data?.tt_twitter_site || data?.pt_twitter_site,
      tags: data?.tt_tags,
      tagsRemote: data?.tt_tags_remote,
      dateFrom: data?.tt_publish_from || data?.tt_date_from,
      dateTo: data?.tt_publish_to || data?.tt_date_to,
      teaserHeadline: this.currentDataset?.entityType === 'article' ? data?.tt_name : data?.tt_headline,
      teaserText: data?.tt_teaser_text,
      teaserImage: data?.tt_teaser_image,
      teaserImageDecorative: data?.tt_teaser_image_decorative,
      teaserImageAltText: data?.tt_teaser_image_alt_text,
      location: data?.tt_location,
      timeTo: data?.tt_time_to,
      timeFrom: data?.tt_time_from,
      topNews: data?.tt_top_news,
      worstCaseNews: data?.tt_worst_case_news,
    }
  }

  private get payloadContentPage () : IBaseMetaData {
    return {
      headline: this.data?.pt_headline,
      description: this.data?.pt_description,
      displaySocialShareBar: this.data?.pt_display_social_share_bar,
      robotsIndexFollow: this.data?.pt_robots_index_follow,
      ogTitle: this.data?.pt_og_title,
      ogSiteName: this.data?.pt_og_site_name,
      ogDescription: this.data?.pt_og_description,
      ogImg: this.data?.pt_og_img,
      twitterCard: this.data?.pt_twitter_card,
      twitterSite: this.data?.pt_twitter_site,
      tagsRemote: this.data?.pt_tags_remote,
      serviceRemote: this.data?.pt_service_remote,
      filterIndustryRemote: this.data?.pt_filter_industry_remote,
      filterServiceDivisionRemote: this.data?.pt_filter_service_division_remote,
      filterServiceObjectRemote: this.data?.pt_filter_service_object_remote,
      additionalTags: this.data?.pt_additional_tags,
      dateFrom: this.data?.pt_publish_from,
      dateTo: this.data?.pt_publish_to,
    }
  }

  public get metaData () : IBaseMetaData {
    return this.isContentProjectionPage ? this.payloadContentProjection : this.payloadContentPage
  }

  private getOpenGraphImage () : IResolution | undefined {
    const { ORIGINAL } = this.metaData.ogImg?.resolutions || {}
    return ORIGINAL
  }

  public get metaTags () : TMetaProperty[] {
    const pageData : IBaseMetaData = this.metaData
    this.pushMetaName('description', pageData.description)
    this.pushMetaName('search_text', pageData.description)

    this.pushMetaName('search_headline', this.searchHeadline ? this.searchHeadline : pageData.headline)

    if (this.isContentProjectionPage) {
      this.pushMetaName('robots', 'index, follow')
    } else {
      this.pushMetaName('robots', pageData.robotsIndexFollow ? 'index, follow' : 'noindex, nofollow')
      this.addMetaTagsServicePage()
    }

    if (this.$ssrContext) {
      this.pushMetaProp('og:url', `https://${this.$ssrContext?.req.headers.host}${this.$route?.fullPath}`)
    } else {
      this.pushMetaProp('og:url', window.location.href)
    }

    const image = this.getOpenGraphImage()
    if (image) {
      this.pushMetaProp('og:image', image.url)
      this.pushMetaProp('og:image:secure_url', image.url)
      this.pushMetaProp('og:image:width', `${image.width || ''}`)
      this.pushMetaProp('og:image:height', `${image.height || ''}`)
    }
    this.pushMetaProp('og:title', pageData.ogTitle)
    this.pushMetaProp('og:site_name', pageData.ogSiteName)
    this.pushMetaProp('og:description', pageData.ogDescription)
    this.pushMetaProp('og:type', 'website')
    this.pushMetaName('twitter:card', pageData.twitterCard)
    this.pushMetaName('twitter:site', pageData.twitterSite)
    this.pushMetaName('twitter:title', pageData.ogTitle)
    this.pushMetaName('twitter:description', pageData.ogDescription)
    this.pushMetaName('twitter:image', pageData.ogImg?.resolutions?.ORIGINAL?.url)
    this.pushMetaName('type', 'page')

    if (this.isContentProjectionPage) {
      this.pushMetaName('type', this.currentDataset?.entityType)
      this.pushMetaName('teaser_headline', pageData.teaserHeadline)
      this.pushMetaName('teaser_text', pageData.teaserText)
      const { teaserImage } = pageData
      const resolutions = mapToResolutions(teaserImage?.resolutions || {})
      this.pushMetaName('teaser_image_retina', resolutions.find((res) => res.name === 'image_w1180_h664')?.url)
      this.pushMetaName('teaser_image_big', resolutions.find((res) => res.name === 'image_w590_h332')?.url)
      this.pushMetaName('teaser_image_medium', resolutions.find((res) => res.name === 'image_w516_h290')?.url)
      this.pushMetaName('teaser_image_small', resolutions.find((res) => res.name === 'image_w258_h145')?.url)
      this.pushMetaName(
        'teaser_image_alt_text',
        pageData.teaserImageDecorative ? undefined : pageData.teaserImageAltText,
      )

      // event
      this.pushMetaName('time_from', formatJoinFromToDate({
        from: pageData.timeFrom, dateOptions: { timeStyle: 'short' },
      }))
      this.pushMetaName('time_to', formatJoinFromToDate({ from: pageData.timeTo, dateOptions: { timeStyle: 'short' } }))
      this.pushMetaName('location', pageData.location)

      // press
      this.pushMetaName('top_news', pageData.topNews?.toString())
      this.pushMetaName('worst_case_news', pageData.worstCaseNews?.toString())

      this.pushMetaName('display_date', formatJoinFromToDate({ from: pageData.dateFrom, to: pageData.dateTo }))
    }
    this.pushMetaName('date_from', formatJoinFromToDate({ from: pageData.dateFrom }))
    this.pushMetaName('date_to', formatJoinFromToDate({ from: pageData.dateTo }))

    pageData.additionalTags?.split(',')?.forEach((tag) => this.pushMetaName('additional_tag', tag))

    if (this.currentDataset?.schema?.includes('global_data')) {
      this.payloadContentProjection.tags?.forEach((tag) => this.pushTag(tag))
    } else {
      // normal pages + 'local_data' (content projection pages)
      this.tagsRemote.forEach((tag) => this.pushTag(tag))
    }

    if (this.useAdditionalTagsExternally) {
      this.pushMetaName('keywords', pageData.additionalTags)
    }

    return this.getMetaTags()
  }

  private addMetaTagsServicePage () : void {
    if (!this.serviceRemote) return
    this.pushMetaName('teaser_headline', this.serviceRemote.data?.tt_name)
    this.pushMetaName('teaser_text', this.serviceRemote.data?.tt_teaser_text)

    const teaserImage = this.serviceRemote.data?.tt_teaser_image
    const resolutions = mapToResolutions(teaserImage?.resolutions || {})
    this.pushMetaName('teaser_image_retina', resolutions.find((res) => res.name === 'image_w1180_h664')?.url)
    this.pushMetaName('teaser_image_big', resolutions.find((res) => res.name === 'image_w590_h332')?.url)
    this.pushMetaName('teaser_image_medium', resolutions.find((res) => res.name === 'image_w516_h290')?.url)
    this.pushMetaName('teaser_image_small', resolutions.find((res) => res.name === 'image_w258_h145')?.url)

    this.pushMetaName(
      'teaser_image_alt_text',
      this.serviceRemote.data?.tt_teaser_image_decorative ? undefined : this.serviceRemote.data?.tt_teaser_image_alt_text,
    )

    this.filters.forEach((filter, index) => {
      this.pushMetaName(`filter_${index + 1}`, `${filter.id}||${filter.data.tt_name}`)
      this.pushMetaName(`filter_id_${index + 1}`, filter.id)
    })
  }

  private get useAdditionalTagsExternally () : boolean {
    return !!this.globalSettings?.data?.ps_country_project_settings?.pt_use_additional_tags_also_extern
  }
}
